import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-modal-select-location',
  templateUrl: './modal-select-location.component.html',
  styleUrls: ['./modal-select-location.component.css']
})
export class ModalSelectLocationComponent implements OnInit {
  global_: any;
  loading: any = false;
  estados: any = [];
  estadoSelecionado: any = 0;
  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    private viacep: NgxViacepService,
    public global: Global,
    public dialogRef: MatDialogRef<ModalSelectLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.authService.locations.subscribe((res) => {
      if (res) {
        this.estados = JSON.parse(res);
        this.estadoSelecionado = this.estados[0].id;
      }
    });
  }

  ngOnInit() {

  }

  showAlert(msg) {
    this.alerts.notifyError({
      body: `Erro ${msg}`,
      title: 'Erro'
    });
  }



  changeState() {
    var selected = this.estados.filter(x => x.id == this.estadoSelecionado);
    localStorage.setItem('@DIngress:location', JSON.stringify(selected));
    this.authService.setLocation(JSON.stringify(selected));
    this.dialogRef.close(selected);
  }
}
