import { Component, Input, OnChanges } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var $: any;
declare var Swiper: any;
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalTransferComponent } from '../../modal-transfer/modal-transfer.component';

@Component({
  selector: 'app-see-ticket',
  templateUrl: './see-ticket.component.html',
  styleUrls: ['./see-ticket.component.css']
})
export class SeeTicketComponent implements OnChanges {

  @Input() tickets: any = null;
  global_: any;
  indexTicket: any = 0;
  constructor(private global: Global,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    public dialog: MatDialog) {
    this.global_ = this.global;
  }

  ngOnChanges() {
    let that = this;
  }

  transferTicket(ticket) {
    const dialogRef = this.dialog.open(ModalTransferComponent, {
      width: 'auto',
      data: ticket
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  prevTicket() {
    if (this.indexTicket > 0) {
      this.indexTicket = this.indexTicket - 1;
    }
  }

  nextTicket() {
    if (this.indexTicket < this.tickets.bilhetes.length - 1) {
      this.indexTicket = this.indexTicket + 1;
    }
  }

  openIngresso(item) {
    if (item.externo) {
      window.open(`${this.global.apiUrlDonwload}BilheteExterno/${item.uuid}/Pdf`, "_blank");
    } else {
      window.open(`${this.global.apiUrlDonwload}Bilhetes/${item.codigo}/Pdf`, "_blank");
    }
  }


  enviarIngresso(item) {
    if (item.externo) {
      this.apiService.Get(`BilheteExterno/${item.uuid}/Pdf/Enviar`).then((res) => {
        this.alerts.notifyInfo({
          body: `Enviado com sucesso`,
          title: 'Enviado'
        });
      }).catch((err) => {
      });
    } else {
      this.apiService.Get(`Bilhete/${item.codigo}/Pdf/Enviar`).then((res) => {
        this.alerts.notifyInfo({
          body: `Enviado com sucesso`,
          title: 'Enviado'
        });
      }).catch((err) => {
      });

    }

  }

}
