import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './service/auth/auth.service';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
declare var MercadoPago: any;
declare var mp: any;
import { filter, map } from 'rxjs/operators';
import { ApiService } from './service/apiServices';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'digitalIngressos';
  configGeral: any = {};
  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
  ) {
    var currentUser = localStorage.getItem('@DIngress:currentUser');
    if (currentUser) {
      this.authService.saveUser(JSON.parse(currentUser));
    }
    setTimeout(() => {
      mp = new MercadoPago(environment.PUBLIC_KEY);
    }, 1000);
    this.getConfigGeral();
  }

  onActivate(event) {
    // Mercadopago.setPublishableKey(environment.PUBLIC_KEY);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }

  ngOnInit() {
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  getConfigGeral() {
    this.apiService.GetPublic(`ConfiguracaoGeral`).then((res) => {
      this.configGeral = res;
    }).catch((err) => {
      if (err.status == 401) {
        
      } else {
      }
    });
  }
}

