import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-modal-transfer',
  templateUrl: './modal-transfer.component.html',
  styleUrls: ['./modal-transfer.component.css']
})
export class ModalTransferComponent implements OnInit {
  user: any = {};
  global_: any;
  loading: any = false;
  ticket: any = {};
  cpfInvalid: any = false;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    private viacep: NgxViacepService,
    public global: Global,
    public dialogRef: MatDialogRef<ModalTransferComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.ticket = data;
  }

  ngOnInit() {

  }

  submitUser() {

    var obj = Object.assign({}, this.user)

    if (obj.cliente_nome == null || obj.cliente_nome == "") {
      this.alerts.notifyError({
        body: `Erro: preencher Nome destino`,
        title: 'Erro'
      });
      return;
    }

    if (obj.cliente_cpf == null || obj.cliente_cpf == "") {
      this.alerts.notifyError({
        body: `Erro: preencher Cpf destino`,
        title: 'Erro'
      });
      return;
    }

    if (obj.cliente_email == null || obj.cliente_email == "") {
      this.alerts.notifyError({
        body: `Erro: preencher Email destino`,
        title: 'Erro'
      });
      return;
    }


    if (obj.cliente_celular == null || obj.cliente_celular == "") {
      this.alerts.notifyError({
        body: `Erro: preencher telefone destino`,
        title: 'Erro'
      });
      return;
    }

    if (!this.regexTelefone.test(obj.cliente_celular)) {
      this.alerts.notifyError({
        body: `Campo telefone inválido`,
        title: 'Erro'
      });
      this.loading = false;
      return;
    }


    var telefone = `+55${obj.cliente_celular.substring(0, 2)}${obj.cliente_celular.substring(2)}`

    obj.cliente_celular = telefone;
    // var obj = { ...this.user };
    this.sendUser(this.ticket.codigo, obj);
  }

  sendUser(codigo, dada) {
    this.loading = true;
    this.apiService.Post(`Bilhete/${codigo}/Cliente`, dada).then((res) => {
      this.alerts.notifySuccess({
        body: `Sucesso ao trasnferir`,
        title: 'Sucesso'
      });
      this.loading = false;
      this.dialogRef.close();
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro: ${err.error}`,
        title: 'Erro'
      });
    });
  }

  validarCPF(inputCPF) {
    if (!inputCPF) {
      this.cpfInvalid = false;
      return false;
    }
    var soma = 0;
    var resto;
    if (inputCPF == '00000000000') return false;
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) {
      this.cpfInvalid = false;
      return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) {
      this.cpfInvalid = false;
      return false;
    }

    this.cpfInvalid = true;
    return false;
  }

}
