import { BrowserModule, Meta } from '@angular/platform-browser';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxAlertsModule, NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { NgxMaskModule } from 'ngx-mask';
import { HttpModule } from '@angular/http';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { AppConfig } from './service/app.config';
import { Global } from './global';
import { ApiService } from './service/apiServices';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './screens/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './screens/home/home.component';
import { RegisterComponent } from './screens/register/register.component';
import { SlidePainelComponent } from './components/slide-painel/slide-painel.component';
import { HeaderComponent } from './components/header/header.component';
import { ItemBuyComponent } from './components/item-buy/item-buy.component';
import { BannerSeparador } from './components/banner-separador/banner-separador.component';
import { Blog } from './components/blog/blog.component';
import { EventComponent } from './screens/event/event.component';
import { NgxViacepModule, NgxViacepService } from "@brunoc/ngx-viacep";
import { BannerHeaderComponent } from './components/banner-header/banner-header.component';
import { PaymentComponent } from './screens/payment/payment.component';
import { Ticketsomponent } from './screens/tickets/tickets.component';
import { BoxNavComponent } from './components/box-nav/box-nav.component';
import { MyTicketsComponent } from './components/boxs/my-tickets/my-tickets.component';
import { EditRegisterComponent } from './components/boxs/edit-register/edit-register.component';
import { EditPaymentComponent } from './components/boxs/edit-payment/edit-payment.component';
import { SeeTicketComponent } from './components/boxs/see-ticket/see-ticket.component';
import { TransferTicketComponent } from './components/boxs/transfer-ticket/transfer-ticket.component';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { AgmCoreModule } from '@agm/core';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ModalTransferComponent } from './components/modal-transfer/modal-transfer.component';
import { ModalEditUserComponent } from './components/modal-edit-user/modal-edit-user.component';
import { NgxKjuaModule } from 'ngx-kjua';
import { MyPaymentsComponent } from './components/boxs/my-payments/my-payments.component';
import { AutocompleteOffDirective } from './directivas/autocomplete-off.directive';
import { OnlyLettersDirective } from './directivas/only-letters.directive';
import { ModalLoginCadastroComponent } from './components/modal-login-cadastro/modal-login-cadastro.component';
import { HeaderModalComponent } from './components/header-modal/header-modal.component';
import { ChangePasswordComponent } from './screens/change-password/change-password.component';
import { ResetPasswordComponent } from './screens/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './screens/confirm-email/confirm-email.component';
import { ModalIngressoPosseComponent } from './components/modal-ingresso-posse/modal-ingresso-posse.component';
import { ItemEventComponent } from './components/item-event/item-event.component';
import { ModalSelectLocationComponent } from './components/modal-select-location/modal-select-location.component';
import { ModalCardAddressComponent } from './components/modal-card-address/modal-card-address.component';
import { FACEBOOK_PIXEL_PROVIDER } from './service/facebook-pixel.provider';
import { RegistroUserFotoComponent } from './screens/registro-user-foto/registro-user-foto.component';
import { FileuploadService } from './service/fileupload/fileupload.service';
import { ModalPhotoUserComponent } from './components/modal-photo-user/modal-photo-user.component';
import { TagManagerService } from './service/tagService.service';
import { ModalIngressoSeatsComponent } from './components/modal-ingresso-seats/modal-ingresso-seats.component';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}


registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    HomeComponent,
    RegisterComponent,
    SlidePainelComponent,
    HeaderComponent,
    ItemBuyComponent,
    BannerSeparador,
    Blog,
    EventComponent,
    BannerHeaderComponent,
    PaymentComponent,
    Ticketsomponent,
    BoxNavComponent,
    MyTicketsComponent,
    EditRegisterComponent,
    EditPaymentComponent,
    SeeTicketComponent,
    TransferTicketComponent,
    ModalTransferComponent,
    ModalEditUserComponent,
    MyPaymentsComponent,
    AutocompleteOffDirective,
    OnlyLettersDirective,
    ModalLoginCadastroComponent,
    HeaderModalComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
    ModalIngressoPosseComponent,
    ItemEventComponent,
    ModalSelectLocationComponent,
    ModalCardAddressComponent,
    RegistroUserFotoComponent,
    ModalPhotoUserComponent,
    ModalIngressoSeatsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    HttpModule,
    NgxAlertsModule.forRoot(),
    NgxMaskModule.forRoot(),
    SnotifyModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#2196f3',
      secondaryColour: '#ffffff',
      tertiaryColour: '#ffffff'
    }),
    NgxViacepModule,
    AppRoutingModule,
    NgxKjuaModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCacVLfhKMF334yZHbp_i78QroF0tN1OtQ',
      libraries: ['places']
    }),
    NoopAnimationsModule,
    MatDialogModule
  ],
  providers: [
    Meta,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "pt"
    },
    AuthGuard,
    NgxAlertsService,
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    ApiService,
    Global,
    NgxViacepService,
    FileuploadService,
    TagManagerService,
    FACEBOOK_PIXEL_PROVIDER
  ],
  entryComponents: [
    ModalTransferComponent,
    ModalEditUserComponent,
    ModalLoginCadastroComponent,
    ModalIngressoPosseComponent,
    ModalSelectLocationComponent,
    ModalCardAddressComponent,
    ModalPhotoUserComponent,
    ModalIngressoSeatsComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
