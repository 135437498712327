import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalLoginCadastroComponent } from 'src/app/components/modal-login-cadastro/modal-login-cadastro.component';
declare var Swiper: any;
declare var $: any;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePasswordComponent implements OnInit {
  loading: any = false;
  register: any = {};

  listsEstate: any = [];
  listsCity: any = [];
  listSexo: any = [];

  cpfInvalid: any = false;
  rgInvalid: any = false;
  confirmEmail: any = null;
  confirmPassword: any = null;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  lettersPass: any = false;
  numberPass: any = false;
  caracterPass: any = false;
  lengthPass: any = false;
  token: any = "";

  constructor(public apiService: ApiService,
    private alerts: NgxAlertsService,
    public global: Global,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {    
    this.token = this.route.snapshot.queryParamMap['params'];
  }

  sendValidation() {

    if (this.loading) {
      return;
    }

    if (!this.lengthPass || !this.lettersPass || !this.numberPass ) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo senha corretamente`,
      });
      this.loading = false;

      return;
    }

    if (this.register.senha == "" || this.register.senha == null) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo senha`,
      });
      this.loading = false;

      return;
    }

    if (this.confirmPassword == "" || this.confirmPassword == null) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo confirmar senha`,
      });
      this.loading = false;

      return;
    }

    if (this.confirmPassword != this.register.senha) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Senhas não condizem`,
      });
      this.loading = false;

      return;
    }

    var obj = Object.assign({}, this.register);

    obj.senha = this.global.MD5(obj.senha);

    if (Object.keys(this.token)[0] != null && Object.keys(this.token)[0] != "") {
      this.send(obj.senha);
    } else {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Link inválido`,
      });
    }

  }

  validarSenha(senha) {
    if (senha == null || senha == "" || senha.length <= 5) {
      this.lengthPass = false;
    } else {
      this.lengthPass = true;
    }
    if (!/[A-Za-z.\s_-]/.test(senha)) {
      this.lettersPass = false;
    } else {
      this.lettersPass = true;
    }
    if (!/[0-9]/.test(senha)) {
      this.numberPass = false;

    } else {
      this.numberPass = true;

    }
    // if (!/[*@._-]/.test(senha)) {
    //   this.caracterPass = false;

    // } else {
    //   this.caracterPass = true;

    // }


  }

  send(password) {
    this.loading = true;
    this.apiService.PatchAlterPassword('Auths/Password', password, Object.keys(this.token)[0]).then((res) => {
      setTimeout(() => {
        this.alerts.alertSuccess({
          text: `Sucesso ao alterar senha`,
          title: 'Sucesso'
        });
      });
      this.router.navigate(['login']);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 401) {
        this.alerts.alertError({
          title: 'Atenção',
          text: `Seu link para redefinir a senha está expirado, favor solicitar um novo`,
        });
      } else {
        this.alerts.alertError({
          text: `${err.error}`,
          title: 'Alerta'
        });
      }
    });
  }

}
