import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-modal-ingresso-seats',
  templateUrl: './modal-ingresso-seats.component.html',
  styleUrls: ['./modal-ingresso-seats.component.css']
})
export class ModalIngressoSeatsComponent implements OnInit {
  global_: any;
  loading: any = false;
  ticket: any = {};
  user: any;
  seeEdit: any;
  listsEstate: any = [];
  listsCity: any = [];
  cpfInvalid: any = false;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  listSexo: any = [];
  tickets: any = [];
  seatSelecionado: any;
  sessaoSelecionada: any;
  lista;
  time;
  cupom: any = null;
  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    private viacep: NgxViacepService,
    public global: Global,
    public dialogRef: MatDialogRef<ModalIngressoSeatsComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.seatSelecionado = data.seatSelecionado;
    this.sessaoSelecionada = data.sessaoSelecionada;
    this.cupom = data.cupom;
  }

  ngOnInit() {
    this.getIngressosSetorSeats();
  }

  closeModal() {
    this.dialogRef.close(null);
  }

  showAlert(msg) {
    this.alerts.notifyError({
      body: `Erro ${msg}`,
      title: 'Erro'
    });
  }

  getIngressosSetorSeats() {
    this.apiService.GetPublic(`Sessoes/${this.sessaoSelecionada.codigo}/Mapa/${this.sessaoSelecionada.mapa_codigo}/Ingressos?categoria_seat=${this.seatSelecionado.category.key}`).then((res) => {
      this.tickets = res;
    }).catch((err) => {
      if (err.status == 401) {

      } else {
        this.alerts.alertError({
          title: 'Atenção',
          text: `${err.error}`,
        })
      }
    });
  }


  submitAttr(tickets_) {

    if (this.loading) {
      return;
    }

    var arrayTickes = Object.assign([], tickets_);

    this.loading = true;
    var erros = false;
    arrayTickes.forEach((item => {
      var verfi = item.lista_posses.filter(x => x.email_posse == null || x.celular_posse == null || x.cpf_posse == null || x.nome_posse == null || x.sobrenome_posse == null || x.celular_posse == "" || x.cpf_posse == "" || x.email_posse == "" || x.nome_posse == "" || x.sobrenome_posse == "").filter(x => x.tipo != `Dependente`);
      var verfi2 = item.lista_posses.filter(x => x.cpfInvalid == false);
      var verfi3 = item.lista_posses.filter(x => x.nome_posse == null || x.sobrenome_posse == null || x.nome_posse == "" || x.sobrenome_posse == "").filter(x => x.tipo == `Dependente`);


      if (verfi.length > 0) {
        erros = true;
      } else if (verfi2.length > 0) {
        erros = true;
      } if (verfi3.length > 0) {
        erros = true;
      } else {

        item.lista_posses.forEach(element => {
          if (element.tipo != 'Dependente') {
            element.celular_posse_ = {
              codigo_pais: "55",
              codigo_area: element.celular_posse.substring(0, 2),
              numero: element.celular_posse.substring(2)
            };
            element.celular_posse = element.celular_posse_;
            delete element.celular_posse_;
          }
        });

      }
    }));

    if (erros) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor atribuir os ingressos corretamente`,
      }).then((result) => {
        this.loading = false;
      });
      return;
    }

    this.dialogRef.close(tickets_);

  }


  validarCPF(inputCPF, item) {
    if (!inputCPF) {
      this.cpfInvalid = false;
      item.cpfInvalid = false;
      return false;
    }
    var soma = 0;
    var resto;

    if (inputCPF == '00000000000') return false;
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) {
      this.cpfInvalid = false;
      item.cpfInvalid = false;
      return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) {
      this.cpfInvalid = false;
      item.cpfInvalid = false;
      return false;
    }

    this.cpfInvalid = true;
    item.cpfInvalid = true;
    return false;
  }

  attUsuario(item) {
    //verificar se o usuario já possui um ingresso atrelado a ele;
    // var possui = false;
    // this.tickets.forEach(item => {
    //   var verif = item.lista_posses.filter(x => x.cpf_posse == this.user.cpf);
    //   if (verif.length > 0) {
    //     possui = true;
    //   }
    // })
    // if (possui) {
    //   this.alerts.alertError({
    //     title: 'Atenção',
    //     text: `Já existe um ingresso atribuido a esse cpf`,
    //   }).then((result) => {
    //     this.loading = false;
    //   });
    // } else {      
    //   item.nome_posse = this.user.nome;
    //   item.sobrenome_posse = this.user.sobrenome;
    //   item.email_posse = this.user.email;
    //   item.cpf_posse = this.user.cpf;
    //   item.celular_posse = this.user.telefone != null ? `${this.user.telefone.codigo_area}${this.user.telefone.numero}` : null;
    //   item.comprador_posse = true;
    // }    
    item.nome_posse = this.user.nome;
    item.sobrenome_posse = this.user.sobrenome;
    item.email_posse = this.user.email;
    item.cpf_posse = this.user.cpf;
    item.celular_posse = this.user.telefone != null ? `${this.user.telefone.codigo_area}${this.user.telefone.numero}` : null;
    item.comprador_posse = true;
  }

  formaterCurrency(value) {
    if (value) {
      if (value.includes("R$")) {
        return value.replace("R$", "").trim();
      }
      return value;
    }
    return value
  }

  formatarValorCupomPorcentagem(valor) {
    if (this.cupom) {
      var valorTotal = 0;
      valorTotal = valorTotal + (((valor) * (100 - this.cupom.desconto)) / 100);
      return valorTotal;
    } else {
      return valor;
    }
  }

  selecionarIngresso(ing) {
    ing.selecionado = true;
    this.dialogRef.close(ing);
  }
}
