import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUser = new BehaviorSubject(undefined);
  authToken = new BehaviorSubject(undefined);
  clientes = new BehaviorSubject(undefined);
  currentClient = new BehaviorSubject(undefined);
  client = new BehaviorSubject(undefined);
  paymentData = new BehaviorSubject(undefined);
  currentCheckout = new BehaviorSubject(undefined);
  pdv_codigo = new BehaviorSubject(undefined);
  location = new BehaviorSubject(undefined);
  locations = new BehaviorSubject(undefined);
  eventos = new BehaviorSubject(undefined);
  estados = new BehaviorSubject(undefined);
  eventosAux = new BehaviorSubject(undefined);



  constructor(private router: Router) { }

  async loginUser(user, checkout = "", url = "") {
    await localStorage.setItem('@DIngress:currentUser', JSON.stringify(user));
    await this.authToken.next(user.access_token);
    await this.currentUser.next(user);
    if (url) {
      this.router.navigate([url]);
    } else {
      this.router.navigate(['home']);
    }
    // if (checkout != null && checkout != "") {
    //   this.router.navigate([checkout]);
    // } else {
    //   this.router.navigate(['home']);
    // }
  }

  async saveUser(user) {
    await localStorage.setItem('@DIngress:currentUser', JSON.stringify(user));
    await this.authToken.next(user.access_token)
    await this.currentUser.next(user);
  }


  async logoutUser() {
    await localStorage.clear();
    await this.authToken.next(null);
    await this.currentUser.next(null);
    await this.client.next(null);
    await this.paymentData.next(null);
    this.router.navigate(['login']);
  }

  async logoutUserCheckout() {
    // await this.authToken.next(null);
    await this.currentUser.next(null);
    // await this.client.next(null);
    await this.paymentData.next(null);
    this.router.navigate(['login']);
  }

  async saveClientes(dados) {
    await this.clientes.next(dados);
  }

  async saveCurrentCheckout(dados) {
    await this.currentCheckout.next(dados);
  }

  async saveClient(dados, m) {
    await this.currentClient.next(dados);
    await this.client.next(m);
  }

  async setPayment(dados) {
    await this.paymentData.next(dados);
  }
  async setPdvCodigo(codigo = "") {
    await this.pdv_codigo.next(codigo);
  }

  async authCheck(user) {
    await localStorage.setItem('@DIngress:currentUser', JSON.stringify(user));
    await this.authToken.next(user.access_token)
    await this.currentUser.next(user);
  }
  async setLocation(location: any) {
    if (location) {
      await this.location.next(location);
    }
  }
  async setLocations(location: any) {
    if (location) {
      await this.locations.next(location);
    }
  }
  async setEventos(arrayEventos: any) {
    if (arrayEventos) {
      await this.eventos.next(arrayEventos);
    }
  }
  async setEstados(arrayEstados: any) {
    if (arrayEstados) {
      await this.estados.next(arrayEstados);
    }
  }

  async setEventosAux(arrayEventos: any) {
    if (arrayEventos) {
      await this.eventosAux.next(arrayEventos);
    }
  }
}
