import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-item-event',
  templateUrl: './item-event.component.html',
  styleUrls: ['./item-event.component.css']
})
export class ItemEventComponent implements OnChanges {

  @Input() arrayList: any = [];
  @Input() codigo_pdv: any = "";
  @Input() estado_id: any = "";
  @Input() eventosLista: any = [];
  eventosAux_: any = [];

  constructor(private router: Router, private authService: AuthService) {
    this.authService.eventosAux.subscribe((response) => {
      if (response) {
        this.eventosAux_ = response;
      }
    });
    this.authService.location.subscribe((response) => {
      if (response) {        
        var selected = JSON.parse(response);
        if (selected[0].id == 0) {
          var aux = this.eventosLista.filter(x => x.estado == "BRASIL");
          aux.map((item) => {
            item.eventos = this.eventosAux_
          });
          this.arrayList = aux.filter(x => x.estado == "BRASIL");
        } else {
          var vef = this.eventosLista.filter(x => x.estado == selected[0].nome);
          if (vef.length > 0) {
            const fromIndex = this.eventosLista.indexOf(vef[0]);
            const element = this.eventosLista.splice(fromIndex, 1)[0]
            this.eventosLista.unshift(element);
            var aux = this.eventosLista.filter(x => x.estado == "BRASIL")
            aux.map((item) => {
              item.eventos = [];
            });
            this.arrayList = this.eventosLista;

          } else {
            this.arrayList = [];
          }
        }
      }
    })
    this.init();
  }

  ngOnChanges() {
    this.init();
  }

  async init() {
    var location_ = await localStorage.getItem('@DIngress:location');
    if (location_) {
      var selected = JSON.parse(location_);
      if (selected[0].id == 0) {
        var aux = this.eventosLista.filter(x => x.estado == "BRASIL")
        aux.map((item) => {
          item.eventos = this.eventosAux_
        });
        this.arrayList = aux.filter(x => x.estado == "BRASIL");
      } else {
        
        var vef = this.eventosLista.filter(x => x.estado == selected[0].nome);
        if (vef.length > 0) {
          const fromIndex = this.eventosLista.indexOf(vef[0]);
          const element = this.eventosLista.splice(fromIndex, 1)[0]
          this.eventosLista.unshift(element);
          var aux = this.eventosLista.filter(x => x.estado == "BRASIL")
          aux.map((item) => {
            item.eventos = [];
          });
          this.arrayList = this.eventosLista;

        } else {
          this.arrayList = [];
        }
      }
    } else {
      var aux = this.eventosLista.filter(x => x.estado == "BRASIL")
      aux.map((item) => {
        item.eventos = this.eventosAux_;
      });
      this.arrayList = this.eventosLista.filter(x => x.estado == "BRASIL");
    }
  }

  // filterArray(arrayList_) {
  //   var dados = [];
  //   dados = this.arrayList.filter(x => x.eventos.length > 0);    
  //   var selected = this.authService.location.getValue();

  //   return dados
  // }

  goToEvent(d) {
    if (this.codigo_pdv) {
      this.router.navigate([`/event/${d.codigo}/${this.codigo_pdv}`]);
    } else {
      this.router.navigate(['/event/' + d.codigo]);
    }
  }

  formaterCurrency(value) {
    if (value) {
      if (value.includes("R$")) {
        return value.replace("R$", "").trim();
      }
      return value;
    }
    return value

  }

  formatDateDayMonth(value) {
    if (value) {
      return moment(value).format("DD/MM")
    }

    return "--/--"
  }

  formatDateDay(value) {
    if (value) {
      return moment(value).format("DD")
    }

    return "--/--"
  }

  formatDateMonth(value) {
    if (value) {
      moment.locale('pt-br');
      return moment(value).format("MMM")
    }

    return "--/--"
  }

}
