import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var $: any;
declare var Swiper: any;
@Component({
  selector: 'app-box-nav',
  templateUrl: './box-nav.component.html',
  styleUrls: ['./box-nav.component.css']
})
export class BoxNavComponent implements OnChanges {

  @Input() seeEdit: any;
  @Output() sendNotSeeEdit = new EventEmitter<string>();
  tabSelect: any = 1;
  seeticket: any = false;
  @Input() tickets: any = [];
  @Input() payments: any = [];

  loading: any = false;

  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    public global: Global,) { }

  ngOnChanges() {
  }

  ngOnInit() {
    let that = this;
    $(".btNavUser").click(function (ev) {
      that.sendNotSeeEdit.emit(null);
      var elements = $(".btNavUser");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].classList.contains('btActive')) {
          elements[i].classList.remove('btActive')
        }
      }
      ev.target.classList.add("btActive");
      if (that.seeticket) {
        that.seeticket = false;
      }
    });

    $(".btMobile").click(function (ev) {
      that.sendNotSeeEdit.emit(null);
      var elements = $(".btMobile");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].classList.contains('btMobileActive')) {
          elements[i].classList.remove('btMobileActive')
        }
      }
      ev.target.classList.add("btMobileActive");
      if (that.seeticket) {
        that.seeticket = false;
      }
    });
  }

  selectTab(value_) {
    this.tabSelect = value_;
    if (value_ == 1) {
      this.obterBilhetes();
    } else if (value_ == 2) {
      this.obterBilhetesTransferir();
    } else if (value_ == 3) {
      this.obterBilhetesPagamentos();
    }
    // this.getTickets();
    // this.getPayments();
  }

  obterBilhetes() {
    this.loading = true;
    this.apiService.Get('MeuIngresso/Ingressos/Proximo').then((res) => {
      this.tickets = res;
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar buscar dados`,
        title: 'Erro'
      });
    });
  }


  obterBilhetesTransferir() {
    this.loading = true;
    this.apiService.Get('MeuIngresso/Transferir').then((res) => {
      this.tickets = res;
      this.loading = false;
    }).catch((err) => {
      this.alerts.notifyError({
        body: `Erro ao tentar realizar buscar dados`,
        title: 'Erro'
      });
    });
  }

  obterBilhetesPagamentos() {
    this.loading = true;
    this.apiService.Get('MeuIngresso/Pagamento/Proximo').then((res) => {
      this.payments = res;
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar buscar dados`,
        title: 'Erro'
      });
    });
  }


  setSeeTicket(value) {

    if (value == "true") {
      this.seeticket = true;
    }
  }

  getTickets() {
    this.apiService.Get('Bilhete').then((res) => {
      this.tickets = res;

    }).catch((err) => {
      this.alerts.notifyError({
        body: `Erro ao tentar realizar buscar dados`,
        title: 'Erro'
      });
    });
  }

  getPayments() {
    this.apiService.Get('Pagamentos').then((res) => {
      this.payments = res;

    }).catch((err) => {
      this.alerts.notifyError({
        body: `Erro ao tentar realizar  buscar dados`,
        title: 'Erro'
      });
    });
  }

  logout() {
    this.authService.logoutUser();
  }
}
