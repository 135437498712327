import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-modal-login-cadastro.',
  templateUrl: './modal-login-cadastro.component.html',
  styleUrls: ['./modal-login-cadastro.component.css']
})
export class ModalLoginCadastroComponent implements OnInit {
  global_: any;
  loading: any = false;
  checkout: any = "";
  user: any = {};
  socialLogin: any = {
    palmspay: 1,
    google: 2,
    facebook: 3,
  }


  listsEstate: any = [];
  listsCity: any = [];
  listSexo: any = [];
  register: any = {};

  cpfInvalid: any = false;
  rgInvalid: any = false;
  confirmEmail: any = null;
  confirmPassword: any = null;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  lettersPass: any = false;
  numberPass: any = false;
  caracterPass: any = false;
  lengthPass: any = false;

  cadastrar: any = false;

  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    private viacep: NgxViacepService,
    public global: Global,
    public dialogRef: MatDialogRef<ModalLoginCadastroComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }

  ngOnInit() {
    //  this.getUserCliente();
    this.register.sexo_id = 0;
    this.cadastrar = false;
    this.getStates();
    this.getSexo();
  }

  close(){
    this.dialogRef.close();
  }
  //LOGIN

  sendValidationLogin() {
    if (this.loading) {
      return;
    }
    if (this.user.login == "" || this.user.login == null) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo email`,
      })
      return;
    }

    if (this.user.senha == "" || this.user.senha == null) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo senha`,
      })
      return;
    }

    let obj = Object.assign({}, this.user);
    obj.senha = this.global.MD5(obj.senha);

    this.authUser(obj);
  }

  authUser(user) {
    this.loading = true;
    this.apiService.PostPublic('Auths', user).then((res) => {
      this.authService.authCheck(res);
      this.dialogRef.close('ok');
    }).catch((err) => {
      this.loading = false;

      this.alerts.alertError({
        title: 'Atenção',
        text: `Erro ao tentar realizar Login`,
      })
    });
  }


  //REGISTRO

  sendValidation() {

    if (this.loading) {
      return;
    }

    if (this.register.nome == "" || this.register.nome == null) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo nome`,
      })
      this.loading = false;

      return;
    }


    if (this.register.nome.length < 3) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Campo nome precisa ter mais de 3 caracteres`,
      });
      this.loading = false;

      return;
    }

    if (this.register.sobrenome == "" || this.register.sobrenome == null) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo sobrenome`,
      });
      this.loading = false;

      return;
    }


    if (!this.cpfInvalid) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Cpf inválido, favor preencher com um cpf válido`,
      });
      this.loading = false;
      return;
    }


    if (this.register.cpf == "" || this.register.cpf == null) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo cpf`,
      });
      this.loading = false;

      return;
    }

    if (this.register.cpf.length < 11) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo cpf`,
      });
      this.loading = false;

      return;
    }


    if (this.register.celular == "" || this.register.celular == null) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo telefone`,
      });
      this.loading = false;

      return;
    }

    if (!this.regexTelefone.test(this.register.celular)) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Campo telefone inválido`,
      });
      this.loading = false;

      return;
    }


    if (this.register.cep == "" || this.register.cep == null) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo cep`,
      });
      this.loading = false;

      return;
    }

    if (this.register.estado_id == null || this.register.estado_id == 0) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo estado`,
      });
      this.loading = false;

      return;
    }

    if (this.register.cidade_id == null || this.register.cidade_id == 0) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo cidade`,
      });
      this.loading = false;

      return;
    }

    if (this.register.logradouro == "" || this.register.logradouro == null) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo endereço`,
      });
      this.loading = false;

      return;
    }

    if (this.register.bairro == "" || this.register.bairro == null) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo bairro`,
      });
      this.loading = false;

      return;
    }

    if (this.register.numero == "" || this.register.numero == null) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo numero`,
      });
      this.loading = false;

      return;
    }

    if (this.register.email == "" || this.register.email == null) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo e-mail`,
      });
      this.loading = false;

      return;
    }

    if (this.register.email != this.confirmEmail) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor verificar campo e-mail`,
      });
      this.loading = false;

      return;
    }

    if (!this.lengthPass || !this.lettersPass || !this.numberPass ) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo senha corretamente`,
      });

      this.loading = false;

      return;
    }

    if (this.register.senha == "" || this.register.senha == null) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo senha corretamente`,
      });

      this.loading = false;

      return;
    }

    if (this.confirmPassword == "" || this.confirmPassword == null) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher o campo confirmar senha`,
      });
      this.loading = false;

      return;
    }

    if (this.confirmPassword != this.register.senha) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Senhas não condizem`,
      });
      this.loading = false;

      return;
    }

    var obj = Object.assign({}, this.register);


    obj.telefone = {
      codigo_pais: "55",
      codigo_area: obj.celular.substring(0, 2),
      numero: obj.celular.substring(2)
    };

    obj.endereco = {
      cep: obj.cep,
      logradouro: obj.logradouro,
      numero: obj.numero,
      complemento: obj.complemento,
      bairro: obj.bairro,
      cidade_id: obj.cidade_id,
      estado_id: obj.estado_id
    };

    obj.senha = this.global.MD5(obj.senha);

    if (obj.data == "" || obj.data == null) {

      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor preencher data de nascimento`,
      });

      return;
    } else {
      let nova_data = obj.data.split('/')[2] + "-" + obj.data.split('/')[1] + "-" + obj.data.split('/')[0];
      if (new Date(nova_data + "T00:00:00").toString() == "Invalid Date") {
        this.alerts.alertError({
          title: 'Atenção',
          text: `Favor preencher o campo data nascimento com uma data válida`,
        });
        return;
      }
      obj.data_nascimento = nova_data;
    }

    delete obj.data;
    delete obj.celular;
    delete obj.cep;
    delete obj.logradouro;
    delete obj.complemento;
    delete obj.bairro;
    delete obj.cidade_id;
    delete obj.estado_id;
    delete obj.orgao;
    delete obj.rg;
    this.sendUser(obj);
  }

  validarSenha(senha) {
    if (senha == null || senha == "" || senha.length <= 5) {
      this.lengthPass = false;
    } else {
      this.lengthPass = true;
    }
    if (!/[A-Za-z.\s_-]/.test(senha)) {
      this.lettersPass = false;
    } else {
      this.lettersPass = true;
    }
    if (!/[0-9]/.test(senha)) {
      this.numberPass = false;

    } else {
      this.numberPass = true;

    }
    // if (!/[*@._-]/.test(senha)) {
    //   this.caracterPass = false;

    // } else {
    //   this.caracterPass = true;

    // }
  }

  searchCep() {
    if (this.register.cep != null && this.register.cep != "" && this.register.cep.length == 8) {
      this.getCep(this.register.cep)
    }
  }

  selectState(estado_id) {
    if (estado_id != null && estado_id != 0) {
      this.getCidades(estado_id);
    } else {
      this.register.cidade_id = 0;
    }
  }

  getStates() {
    this.apiService.GetPublic('Estados').then((res) => {
      this.listsEstate = res;
      this.listsEstate.unshift({
        "nome": "Estado",
        id: 0
      });
      this.register.estado_id = 0;
      if (this.register.estado_id != null && this.register.estado_id != 0) {
        this.getCidades(this.register.estado_id);
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar obter lista de estados`,
        title: 'Erro'
      });
    });
  }

  getCidades(id_estado, localidade = null) {
    this.apiService.GetPublic(`Estados/${id_estado}/Cidades`).then((res) => {
      this.listsCity = res;
      this.listsCity.unshift({
        "nome": "Cidade",
        id: 0
      });
      this.register.cidade_id = 0;
      if (localidade != null) {
        let verfiCidade = this.listsCity.filter(x => x.nome == localidade.toUpperCase());
        if (verfiCidade.length > 0) {
          this.register.cidade_id = verfiCidade[0].id;
        }
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar obter lista de cidades`,
        title: 'Erro'
      });
    });
  }

  getCep(cep) {
    this.viacep
      .buscarPorCep(cep)
      .pipe(
        catchError((error: CEPError) => {
          return EMPTY;
        })
      )
      .subscribe((endereco: Endereco) => {
        this.register.logradouro = endereco.logradouro;
        this.register.bairro = endereco.bairro;
        if (endereco.uf) {
          let verfiEstado = this.listsEstate.filter(x => x.sigla == endereco.uf.toUpperCase());
          if (verfiEstado.length > 0) {
            this.register.estado_id = verfiEstado[0].id;
            this.getCidades(this.register.estado_id, endereco.localidade);
          }
        }
      });
  }

  getSexo() {
    this.apiService.GetPublic('Sexo').then((res) => {
      this.listSexo = res;
      this.listSexo.unshift({
        "nome": "Sexo",
        id: 0
      });
      this.register.sexo_id = 0;
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar obter lista de sexo`,
        title: 'Erro'
      });
    });
  }

  sendUser(obj) {
    this.loading = true;
    this.apiService.PostPublic('Clientes', obj).then((res) => {
      let obj_ = {
        login: obj.email,
        senha: obj.senha
      }      
      this.authUser(obj_);      
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro: ${err.error}`,
        title: 'Erro'
      });
    });
  }

  validarCPF(inputCPF) {
    if (!inputCPF) {
      this.cpfInvalid = false;
      return false;
    }
    var soma = 0;
    var resto;
    if (inputCPF == '00000000000') return false;
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) {
      this.cpfInvalid = false;
      return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) {
      this.cpfInvalid = false;
      return false;
    }

    this.cpfInvalid = true;
    return false;
  }

  registerUser() {
    this.cadastrar = !this.cadastrar;
  }

}
