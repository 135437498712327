import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-modal-edit-user',
  templateUrl: './modal-edit-user.component.html',
  styleUrls: ['./modal-edit-user.component.css']
})
export class ModalEditUserComponent implements OnInit {
  global_: any;
  loading: any = false;
  ticket: any = {};
  user: any;
  seeEdit: any;
  listsEstate: any = [];
  listsCity: any = [];
  cpfInvalid:any = false;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  listSexo: any = [];

  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    private viacep: NgxViacepService,
    public global: Global,
    public dialogRef: MatDialogRef<ModalEditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    
  }

  ngOnInit() {
    this.getUserCliente();
  }

  getUserCliente() {
    this.apiService.Get(`Clientes`).then((res) => {
      this.user = res[0];
      this.user.telefone.celular = `${res[0].telefone.codigo_area}${res[0].telefone.numero}`;
      if (this.user.data_nascimento != "0001-01-01T00:00:00") {
        this.user.data = moment(this.user.data_nascimento).format("DD/MM/YYYY");
      }
      this.getStates();
      this.getSexo();

    }).catch((err) => {
      if (err.status == 401) {
        this.authService.logoutUser();
      } else {
        
        this.alerts.notifyError({
          body: `Erro ${err.error}`,
          title: 'Erro'
        });
      }
    });
  }

  getSexo() {
    this.apiService.GetPublic('Sexo').then((res) => {
      this.listSexo = res;
      this.listSexo.unshift({
        "nome": "Sexo",
        id: 0
      });
      if (this.user.sexo_id == null && this.user.sexo == null) {
        this.user.sexo_id = 0;
      } else {
        this.user.sexo_id = this.user.sexo.id;
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar obter lista de sexo`,
        title: 'Erro'
      });
    });
  }


  searchCep() {
    if (this.user.cep != null && this.user.cep != "" && this.user.cep.length == 8) {
      this.getCep(this.user.cep)
    }
  }

  selectState(estado_id) {
    if (estado_id != null && estado_id != 0) {
      this.getCidades(estado_id);
    } else {
      this.user.cidade_id = 0;
    }
  }

  getStates() {
    this.apiService.GetPublic('Estados').then((res) => {
      this.listsEstate = res;
      this.listsEstate.unshift({
        "nome": "Estado",
        id: 0
      });
      // this.user.endereco.estado_id = 0;
      if (this.user.endereco.estado_id != null && this.user.endereco.estado_id != 0) {
        this.getCidades(this.user.endereco.estado_id);
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }

  getCidades(id_estado, localidade = null) {
    this.apiService.GetPublic(`Estados/${id_estado}/Cidades`).then((res) => {
      this.listsCity = res;
      this.listsCity.unshift({
        "nome": "Cidade",
        id: 0
      });
      // this.user.endereco.cidade_id = 0;
      if (localidade != null) {
        let verfiCidade = this.listsCity.filter(x => x.nome == localidade.toUpperCase());
        if (verfiCidade.length > 0) {
          this.user.endereco.cidade_id = verfiCidade[0].id;
        }
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }

  getCep(cep) {
    this.viacep
      .buscarPorCep(cep)
      .pipe(
        catchError((error: CEPError) => {
       
          return EMPTY;
        })
      )
      .subscribe((endereco: Endereco) => {
        this.user.logradouro = endereco.logradouro;
        this.user.bairro = endereco.bairro;
        if (endereco.uf) {
          let verfiEstado = this.listsEstate.filter(x => x.sigla == endereco.uf.toUpperCase());
          if (verfiEstado.length > 0) {
            this.user.estado_id = verfiEstado[0].id;
            this.getCidades(this.user.estado_id, endereco.localidade);
          }
        }
      });
  }

  submitUser() {
    var obj = { ...this.user };
    if (obj.telefone.celular == "" || obj.telefone.celular == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo telefone`,
        title: 'Erro'
      });
      return;
    }



    if (!this.regexTelefone.test(obj.telefone.celular)) {
      this.alerts.notifyError({
        body: `Campo telefone inválido`,
        title: 'Erro'
      });
      return;
    }
    obj.telefone = {
      codigo_pais: "55",
      codigo_area: obj.telefone.celular.substring(0, 2),
      numero: obj.telefone.celular.substring(2)
    };

    if (obj.data == "" || obj.data == null) {
      this.alerts.notifyError({
        body: `Favor preencher data de nascimento`,
        title: 'Erro'
      });
      return;
    } else {
      let nova_data = obj.data.split('/')[2] + "-" + obj.data.split('/')[1] + "-" + obj.data.split('/')[0];
      if (new Date(nova_data + "T00:00:00").toString() == "Invalid Date") {
        this.alerts.notifyError({
          body: `Favor preencher o campo data nascimento com uma data válida`,
          title: 'Erro'
        });
        return;
      }
      obj.data_nascimento = nova_data;
    }
    delete obj.sexo;
    delete obj.telefone.celular;
    delete obj.imagem_link;
    if (!this.cpfInvalid) {
      this.showAlert('Cpf inválido, favor preencher com um cpf válido');
      this.loading = false;
      return;
    }
    this.sendUser(obj)
  }
  
  showAlert(msg) {
    this.alerts.notifyError({
      body: `Erro ${msg}`,
      title: 'Erro'
    });
  }

  sendUser(obj) {
    this.loading = true;
    this.apiService.Put('Clientes', obj).then((res) => {
      this.alerts.notifySuccess({
        body: `Sucesso ao editar`,
        title: 'Sucesso'
      });
      this.loading = false;
      this.getUserCliente();
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro: ${err.error}`,
        title: 'Erro'
      });
    });
  }

  validarCPF(inputCPF) {
    if (!inputCPF) {
      this.cpfInvalid = false;
      return false;
    }
    var soma = 0;
    var resto;
    
    if (inputCPF == '00000000000') return false;
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) {
      this.cpfInvalid = false;
      return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) {
      this.cpfInvalid = false;
      return false;
    }

    this.cpfInvalid = true;
    return false;
  }

}
