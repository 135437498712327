import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var Swiper: any;
declare var $: any;
@Component({
  selector: 'app-slide-painel',
  templateUrl: './slide-painel.component.html',
  styleUrls: ['./slide-painel.component.css']
})
export class SlidePainelComponent implements OnChanges {
  @Input() arrayList: any = [];
  @Input() codigo_pdv: any = "";
  constructor(private router: Router) { }


  ngOnChanges() {
    setTimeout(() => {
      try {
        this.initSlide();
      } catch (error) {

      }
    }, 1000);
  }

  goToRoute(r = "") {
    if (this.codigo_pdv) {
      this.router.navigate([`/${r}/${this.codigo_pdv}`]);
    } else {
      this.router.navigate([`/${r}`]);
    }
  }

  initSlide() {
    const swiperEl: any = document.querySelector('swiper-container#swiperContainer');
    const swiperElMob: any = document.querySelector('swiper-container#swiperContainerMob');
    const params = {
      injectStyles: [`
    .swiper-pagination-bullet {
      width: 40px;
      border-radius: 0px;
      height: 5px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: rgb(0, 0, 0);
      opacity: 1;
      background: rgba(0, 0, 0, 0.2);
  }

    .swiper-pagination-bullet-active {
      color: #fff;
      background: #fff;
    }

    .swiper-button-next {
        text-indent: -9999px;
        background: url(./assets/img/bts/set_dir.png) center no-repeat !important;
    }
    
    .swiper-button-prev {
        text-indent: -9999px;
        background: url(./assets/img/bts/set_esq.png) center no-repeat !important;
    }
    .swiper-button-next path {
        display: none;
      }

      .swiper-button-prev path {
        display: none;
      }
    `
      ],
      pagination: {
        clickable: true
      },
      loop: true,
      autoplay: {
        delay: 4000,
      },
    }

    Object.assign(swiperEl, params)
    Object.assign(swiperElMob, params)

    swiperEl.initialize();
    swiperElMob.initialize();
  }

  openUrl() {
    window.open('https://allrede.com.br/', '_blank');
  }


  checkBanner(banner) {
    if (banner.url) {
      window.open(banner.url, '_blank');
    } else {
      this.goToRoute('event/' + banner.codigo)
    }
  }

}
