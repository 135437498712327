import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from "@angular/platform-browser";
import { FileuploadService } from 'src/app/service/fileupload/fileupload.service';
declare var tracking: any;
declare var $: any;
@Component({
  selector: 'app-registro-user-foto',
  templateUrl: './registro-user-foto.component.html',
  styleUrls: ['./registro-user-foto.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RegistroUserFotoComponent implements OnInit {
  loading: any = false;
  @ViewChild("fileInput", { static: false }) fileInput;
  @ViewChild('video', { static: true }) videoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  file: any;
  imgfile: any;
  areaCaptura: any = {};
  token: any;
  sucesso: any = false;
  videoStream: any; // Variável para armazenar o stream de vídeo
  constructor(public apiService: ApiService,
    public global: Global,
    private titleService: Title,
    private route: ActivatedRoute,
    public uploadService: FileuploadService,
    private alerts: NgxAlertsService,
  ) {
    this.titleService.setTitle('Digital Ingressos');
  }

  ngOnInit() {
    const cValue = this.getParameterByName('c', window.location.href);
    if (cValue) {
      this.token = cValue;
    }
    setTimeout(() => {
      this.initCamera();
    }, 1000);
  }

  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  openfile() {
    this.fileInput.nativeElement.click();
  }

  initCamera() {
    $("#picture").hide();
    const video: any = document.getElementById('video');
    const canvas: any = document.getElementById('canvas');
    const context = canvas.getContext('2d');

    // Iniciar a câmera usando getUserMedia()
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        this.videoStream = stream;  // Armazena o stream para parar a câmera mais tarde
        video.srcObject = stream; // Conecta o stream ao elemento de vídeo
        video.play();
      })
      .catch((err) => {
        console.error("Erro ao acessar a câmera: ", err);
      });
  }

  capturarImagem() {
    let that = this;
    that.loading = true;

    // Captura o elemento de vídeo
    const video: any = document.querySelector("#video");

    // Criando um canvas para guardar a imagem temporariamente
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');

    // Desenha o frame do vídeo no canvas
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    that.loading = false;

    // Converte o canvas para um Blob (necessário para envio)
    canvas.toBlob((blob: Blob) => {
      // Armazenar o blob na variável imgfile
      that.imgfile = blob;

      // Exibir a imagem capturada (opcional)
      document.querySelector("#imagemConvertida").setAttribute("src", URL.createObjectURL(blob));
      // Parar o vídeo e desligar a câmera
      that.videoStream.getTracks().forEach(track => track.stop()); // Desliga a câmera
    }, 'image/jpeg');

    setTimeout(() => {
      $("#picture").show();
      $("#videoPlayer").hide();
      $('.btnCapturar').prop('hidden', true);
      $('.btnNovaCaptura').prop('hidden', false);
      if (that.videoStream) {
        that.videoStream.getTracks().forEach(track => track.stop()); // Desliga a câmera
      }
    }, 1000);

  }


  // initCamera() {
  //   $("#picture").hide();
  //   let that = this;
  //   var cont = 0;
  //   const video = document.getElementById('video');
  //   const canvas: any = document.getElementById('canvas');
  //   const context: any = canvas.getContext('2d');
  //   var person = new tracking.ObjectTracker(['face']);
  //   person.setInitialScale(4);
  //   person.setStepSize(2);
  //   person.setEdgesDensity(0.1);
  //   tracking.track('#video', person, { camera: true });
  //   person.on('track', function (event) {
  //     context.clearRect(0, 0, canvas.width, canvas.height);
  //     if (event.data.length === 0) {

  //     } else {
  //       // event.data.forEach(rect => {
  //       //   context.strokeStyle = '#722bae';
  //       //   context.lineWidth = 5;
  //       //   context.strokeRect(rect.x, rect.y, rect.width, rect.height);
  //       // });
  //       if (cont == 0) {
  //         // that.capturarImagem();
  //       }
  //       cont = 1;
  //     }
  //   });
  // };

  // capturarImagem() {
  //   let that = this;
  //   that.loading = true;
  //   //Captura elemento de vídeo
  //   var video: any = document.querySelector("#video");

  //   //Criando um canvas que vai guardar a imagem temporariamente
  //   var canvas = document.createElement('canvas');
  //   canvas.width = video.videoWidth;
  //   canvas.height = video.videoHeight;
  //   var ctx = canvas.getContext('2d');

  //   ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  //   //Criando o JPG
  //   var dataURI = canvas.toDataURL('image/jpeg');

  //   document.querySelector("#imagemConvertida").setAttribute("src", dataURI);
  //   that.imgfile = dataURI;

  //   canvas.toBlob((blob: Blob) => {
  //     // Armazenar o blob na variável imgfile
  //     that.imgfile = blob;

  //     // Exibir a imagem capturada (opcional)
  //     document.querySelector("#imagemConvertida").setAttribute("src", URL.createObjectURL(blob));

  //     // Exibir e ocultar elementos conforme necessário (opcional)
  //     $("#picture").show();
  //     $("#videoPlayer").hide();
  //     $('.btnCapturar').prop('hidden', true);
  //     $('.btnNovaCaptura').prop('hidden', false);
  //     that.loading = false;
  //   }, 'image/jpeg');

  // };

  reiniciarCaptura() {
    let that = this;
    document.querySelector("#imagemConvertida").setAttribute("src", '');
    $("#picture").hide();
    that.imgfile = null;
    $("#videoPlayer").show();
    $('.btnCapturar').prop('hidden', false);
    $('.btnNovaCaptura').prop('hidden', true);
    this.initCamera();
  }

  sendSnapShot(base64) {
    document.querySelector("#imagemConvertida").setAttribute("src", base64);
    document.querySelector("#caminhoImagem a").setAttribute("href", base64);
  };

  enviarFoto() {
    this.uploadFoto(this.imgfile);
  }

  uploadFoto(fileToUpload) {
    this.loading = true;
    this.uploadService.pushFileToStorage(fileToUpload, this.token).then(
      (result: any) => {
        if (result["ok"] == false) {
          var img = {
            "imagem": result["error"].text
          }
          this.postPhoto(img);
        }
        this.imgfile = null;
        this.loading = false;
      },
      err => {
        this.loading = false;
        if (err.status == 401) {
          return;
        }
      }
    );
  }

  postPhoto(obj) {
    this.loading = true;
    var url = `Clientes/Foto`;
    this.apiService.PostPhoto(url, obj, this.token).then((res: any) => {
      this.imgfile = null;
      this.loading = false;
      this.sucesso = true;
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyInfo({
        body: err.error,
        title: 'Alerta'
      });
    });
  }

}


