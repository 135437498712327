import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Global } from "../../global";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";


@Injectable()
export class FileuploadService {
  currentUser: any;
  constructor(
    private http: HttpClient,
    private global: Global,
    public router: Router,
    private auth: AuthService,
  ) {
    // this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.auth.currentUser.subscribe((res: any) => {
      if (res) {
        this.currentUser = res;
      }
    });
  }

  pushFileToStorage(file: File, token: any) {
    const uploadedFile: FormData = new FormData();
    uploadedFile.append("uploadedFile", file);
    return new Promise((resolve, reject) => {
      this.http
        .post(this.global.apiUrl + "Files/Upload/Foto", uploadedFile, {
          headers: new HttpHeaders().set(
            "Authorization",
            "Bearer " + token
          )
        })
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            if (err.status == 200) {
              resolve(err);
            } else {
              reject(err);
            }
          }
        );
    });
  }
}
