import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { ModalSelectLocationComponent } from 'src/app/components/modal-select-location/modal-select-location.component';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var Swiper: any;
declare var $: any;
import { Meta, Title } from "@angular/platform-browser";
import { async } from '@angular/core/testing';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  user: any = {};
  events: any = [];
  eventsBanners: any = [];
  eventsLocations: any = [];
  codigo_pdv: any = "";
  locations_: any = [];
  estado_id: any;
  estado: any;
  estados: any = [];

  constructor(
    private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    public global: Global,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private title: Title,
    private meta: Meta
  ) {
    var currentUser = localStorage.getItem('@DIngress:currentUser');
    localStorage.setItem('@DIngress:checkOutData', "");
    localStorage.setItem('@DIngress:checkOutDataCode', "");
    localStorage.setItem('@DIngress:checkOutDataArea', "");
    this.user = currentUser;
    this.authService.locations.subscribe((res) => {
      if (res) {
        var locations_ = JSON.parse(res);
        this.locations_ = JSON.parse(res);
        // this.getEventsLocations(locations_[0], 0, locations_);
        this.authService.location.subscribe((response) => {
          if (response) {
            var selected = JSON.parse(response)
            this.getEventsBanners(selected[0].id);
          }
        })
      }
    });
    this.authService.eventos.subscribe((res) => {
      if (res) {
        this.events = res;
      }
    });
    // this.openModalLocation();
  }

  ngOnInit() {
    $("body").attr('style', 'background-color: #fff');
    const codigo_pdv = this.route.snapshot.paramMap.get('codigo_pdv');
    if (codigo_pdv) {
      this.codigo_pdv = codigo_pdv;
    }
    this.authService.setPdvCodigo(this.codigo_pdv);
    this.getEstados();

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);


    this.title.setTitle('Digital Ingressos');
  }

  async init() {
    var location_ = await localStorage.getItem('@DIngress:location');
    if (location_) {
      var selected = JSON.parse(location_);
      this.getEventsBanners(selected[0].id);
      this.getEventsLocations();
    } else {
      this.getEventsLocations();
      this.getEventsBanners();
    }
  }


  getEstados() {
    let cod_ = "";
    if (this.codigo_pdv) {
      cod_ = `Eventos/Estados?codigo_pdv=${this.codigo_pdv}`;
    } else {
      cod_ = `Eventos/Estados`;
    }
    this.apiService.GetPublic(cod_).then(async (res) => {
      this.estados = res;
      var obj = {
        "nome": "BRASIL",
        "sigla": "BR",
        "cidades": [],
        "id": 0
      };

      this.estados.unshift(obj);
      this.init();
      this.authService.setEstados(res);
    }).catch((err) => {
      this.init();
    });
  }

  getEvents(estado_id = null) {
    if (!estado_id) {
      return;
    }

    var url = "";

    if (this.estado != null && this.estado.id == estado_id) {
      return
    }
    this.estado = { id: estado_id };
    this.codigo_pdv = this.route.snapshot.paramMap.get('codigo_pdv');
    if (this.codigo_pdv) {
      url = `Eventos?status=ATIVO&codigo_pdv=${this.codigo_pdv}&estado_id=${estado_id}`
    } else {
      url = `Eventos?status=ATIVO&estado_id=${estado_id}`;
    }
    this.apiService.GetPublic(url).then((res) => {
      this.events = res;
    }).catch((err) => {
      if (err.status == 401) {
        // this.authService.logoutUser();
      } else {
        this.alerts.notifyError({
          body: `Erro ao tentar obter eventos`,
          title: 'Erro'
        });
      }
    });
  }

  getEventsLocations() {
    var url = "";
    this.codigo_pdv = this.route.snapshot.paramMap.get('codigo_pdv');
    if (this.codigo_pdv) {
      url = `Eventos?status=ATIVO&codigo_pdv=${this.codigo_pdv}`
    } else {
      url = `Eventos?status=ATIVO`;
    }
    this.apiService.GetPublic(url).then(async (res) => {
      var ev: any = res;
      this.authService.setEventosAux(ev);
      this.eventsLocations = [];
      this.estados.forEach((item) => {
        var obj = {
          estado: item.nome,
          eventos: ev.filter(x => x.endereco.estado == item.nome)
        }
        this.eventsLocations.push(obj)
      });

      var obj = {
        estado: "BRASIL",
        eventos: ev
      }
      if (this.eventsLocations.filter(x => x.estado == "BRASIL").length == 0) {
        this.eventsLocations.push(obj);
      }
      this.authService.setEventos(this.eventsLocations)
    }).catch((err) => {
      if (err.status == 401) {
      } else {
        this.alerts.notifyError({
          body: `Erro ao tentar obter eventos`,
          title: 'Erro'
        });
      }
    });
  }

  getEventsBanners(estado_id = null) {

    if (this.estado_id == estado_id && estado_id != null) {
      return
    }
    this.estado_id = estado_id;
    var url = "";
    this.codigo_pdv = this.route.snapshot.paramMap.get('codigo_pdv');
    if (this.codigo_pdv && estado_id) {
      url = `Eventos/Banners?status=ATIVO&codigo_pdv=${this.codigo_pdv}&estado_id=${estado_id}`
    } else if (this.codigo_pdv) {
      url = `Eventos/Banners?status=ATIVO&codigo_pdv=${this.codigo_pdv}`;
    } else if (estado_id) {
      url = `Eventos/Banners?status=ATIVO&estado_id=${estado_id}`;
    } else {
      url = `Eventos/Banners?status=ATIVO`;
    }
    this.apiService.GetPublic(url).then((res) => {
      this.eventsBanners = res;
    }).catch((err) => {
      if (err.status == 401) {
        // this.authService.logoutUser();
      } else {
        this.alerts.notifyError({
          body: `Erro ao tentar obter eventos`,
          title: 'Erro'
        });
      }
    });
  }

  createMetaTags(event) {
    var description = document.createElement('meta');
    description.setAttribute('name', 'description');
    description.setAttribute('content', event.observacao);
    document.head.appendChild(description);

    var title = document.createElement('meta');
    title.setAttribute('name', 'title');
    title.setAttribute('content', `${event.nome} | ${event.local} | Digital Ingressos`);
    document.head.appendChild(title);

    var url = document.createElement('meta');
    url.setAttribute('name', 'url');
    url.setAttribute('content', window.location.href);
    document.head.appendChild(url);

    var image = document.createElement('meta');
    image.setAttribute('name', 'image');
    image.setAttribute('content', `https://${event.imagem_banner_interno_link}`);
    document.head.appendChild(image);

    var nome = document.createElement('meta');
    nome.setAttribute('name', 'nome');
    nome.setAttribute('content', event.nome);
    document.head.appendChild(nome);

    var local = document.createElement('meta');
    local.setAttribute('name', 'local');
    local.setAttribute('content', event.local);
    document.head.appendChild(local);

    var data_inicio = document.createElement('meta');
    data_inicio.setAttribute('name', 'data_inicio');
    data_inicio.setAttribute('content', event.data_inicio);
    document.head.appendChild(data_inicio);


    var site = document.createElement('meta');
    site.setAttribute('name', 'site');
    site.setAttribute('content', 'Digital Ingressos');
    document.head.appendChild(site);

    var Ogdescription = document.createElement('meta');
    Ogdescription.setAttribute('name', 'og:description');
    Ogdescription.setAttribute('content', event.observacao);
    document.head.appendChild(Ogdescription);

    var Ogtitle = document.createElement('meta');
    Ogtitle.setAttribute('name', 'og:title');
    Ogtitle.setAttribute('content', `${event.nome} | ${event.local} | Digital Ingressos`);
    document.head.appendChild(Ogtitle);

    var Ogurl = document.createElement('meta');
    Ogurl.setAttribute('name', 'og:url');
    Ogurl.setAttribute('content', window.location.href);
    document.head.appendChild(Ogurl);

    var Ogimage = document.createElement('meta');
    Ogimage.setAttribute('name', 'og:image');
    Ogimage.setAttribute('content', `https://${event.imagem_banner_interno_link}`);
    document.head.appendChild(Ogimage);

  }

  // openModalLocation() {
  //   const dialogRef = this.dialog.open(ModalSelectLocationComponent, {
  //     width: '300px',
  //     height: 'auto',
  //     maxWidth: '100%',
  //     data: this.locations_,
  //      disableClose: true
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result == 'ok') {

  //     }
  //   });
  // }

}
