import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChangePasswordComponent } from './screens/change-password/change-password.component';
import { ConfirmEmailComponent } from './screens/confirm-email/confirm-email.component';
import { EventComponent } from './screens/event/event.component';
import { HomeComponent } from './screens/home/home.component';
import { LoginComponent } from './screens/login/login.component';
import { PaymentComponent } from './screens/payment/payment.component';
import { RegisterComponent } from './screens/register/register.component';
import { ResetPasswordComponent } from './screens/reset-password/reset-password.component';
import { Ticketsomponent } from './screens/tickets/tickets.component';
import { AuthGuard } from './_guards/auth.guard';
import { RegistroUserFotoComponent } from './screens/registro-user-foto/registro-user-foto.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'login', component: LoginComponent, pathMatch: 'full',
    data: {
      title: 'Digital ingressos',
      descrption: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogTitle: 'Digital ingressos',
      ogImage: 'https://digitalingressos.com.br/assets/img/logos/logo_digital.png'
    }
  },
  {
    path: 'login/:codigo_pdv', component: LoginComponent, pathMatch: 'full',
    data: {
      title: 'Digital ingressos',
      descrption: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogTitle: 'Digital ingressos',
      ogImage: 'https://digitalingressos.com.br/assets/img/logos/logo_digital.png'
    }
  },

  {
    path: 'register', component: RegisterComponent, pathMatch: 'full',
    data: {
      title: 'Digital ingressos',
      descrption: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogTitle: 'Digital ingressos',
      ogImage: 'https://digitalingressos.com.br/assets/img/logos/logo_digital.png'
    }
  },
  {
    path: 'register/:codigo_pdv', component: RegisterComponent, pathMatch: 'full',
    data: {
      title: 'Digital ingressos',
      descrption: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogTitle: 'Digital ingressos',
      ogImage: 'https://digitalingressos.com.br/assets/img/logos/logo_digital.png'
    }
  },

  {
    path: 'home', component: HomeComponent, pathMatch: 'full',
    data: {
      title: 'Digital ingressos',
      descrption: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogTitle: 'Digital ingressos',
      ogImage: 'https://digitalingressos.com.br/assets/img/logos/logo_digital.png'
    }
  },
  {
    path: 'home/:codigo_pdv', component: HomeComponent, pathMatch: 'full',
    data: {
      title: 'Digital ingressos',
      descrption: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogTitle: 'Digital ingressos',
      ogImage: 'https://digitalingressos.com.br/assets/img/logos/logo_digital.png'
    }
  },

  {
    path: 'event/:codigo', component: EventComponent, pathMatch: 'full',
    data: {
      title: 'Evento',
      descrption: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogTitle: 'Evento',
      ogDescription: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogImage: 'https://digitalingressos.com.br/assets/img/logos/logo_digital.png'
    }
  },
  {
    path: 'event/:codigo/:codigo_pdv', component: EventComponent, pathMatch: 'full',
    data: {
      title: 'Evento',
      descrption: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogTitle: 'Evento',
      ogDescription: 'Digital Ingressos - Inovação + tecnologia + segurança = uma nova plataforma de venda de ingressos.',
      ogImage: 'https://digitalingressos.com.br/assets/img/logos/logo_digital.png'
    }
  },

  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'payment/:codigo_pdv', component: PaymentComponent, canActivate: [AuthGuard], pathMatch: 'full' },


  { path: 'tickets', component: Ticketsomponent, canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'tickets/:codigo_pdv', component: Ticketsomponent, canActivate: [AuthGuard], pathMatch: 'full' },

  { path: 'reset', component: ChangePasswordComponent },
  { path: 'confirm-email', component: ConfirmEmailComponent },
  { path: 'reset-password', component: ResetPasswordComponent, pathMatch: 'full' },
  { path: 'foto', component: RegistroUserFotoComponent, pathMatch: 'full' },

  //ROTAS PDV
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
