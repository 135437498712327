import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  loading: any = false;
  register: any = {};

  listsEstate: any = [];
  listsCity: any = [];
  listSexo: any = [];

  cpfInvalid: any = false;
  rgInvalid: any = false;
  confirmEmail: any = null;
  confirmPassword: any = null;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  lettersPass: any = false;
  numberPass: any = false;
  caracterPass: any = false;
  lengthPass: any = false;

  constructor(public apiService: ApiService,
    private alerts: NgxAlertsService,
    public global: Global,
    private authService: AuthService,
    private viacep: NgxViacepService,
    private router: Router,
    private titleService: Title
  ) {
    this.titleService.setTitle('Digital Ingressos');
    localStorage.setItem('@DIngress:checkOutData', "");
    localStorage.setItem('@DIngress:checkOutDataCode', "");
    localStorage.setItem('@DIngress:checkOutDataArea', "");
    this.register.sexo_id = 0;
  }

  ngOnInit() {
    this.getStates();
    this.getSexo();
  }

  sendValidation() {

    if (this.loading) {
      return;
    }

    if (this.register.nome == "" || this.register.nome == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo nome`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }


    if (this.register.nome.length < 3) {
      this.alerts.notifyError({
        body: `Campo nome precisa ter mais de 3 caracteres`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.register.sobrenome == "" || this.register.sobrenome == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo sobrenome`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    // if (this.register.sobrenome.length < 4) {
    //   this.alerts.notifyError({
    //     body: `Campo sobrenome precisa ter mais de 4 caracteres`,
    //     title: 'Erro'
    //   });
    //   this.loading = false;

    //   return;
    // }

    // var sobrenome_ = this.register.sobrenome.split(" ");
    // if (sobrenome_.length > 1) {
    //   if (sobrenome_[sobrenome_.length - 1].length < 4) {
    //     this.alerts.notifyError({
    //       body: `Campo sobrenome precisa ter mais de 4 caracteres`,
    //       title: 'Erro'
    //     });
    //     this.loading = false;

    //     return;
    //   }

    // }

    if (!this.cpfInvalid) {
      this.alerts.notifyError({
        body: 'Cpf inválido, favor preencher com um cpf válido',
        title: 'Erro'
      });
      this.loading = false;
      return;
    }


    if (this.register.cpf == "" || this.register.cpf == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo cpf`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.register.cpf.length < 11) {
      this.alerts.notifyError({
        body: `Favor preencher o campo cpf`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.register.sexo_id == "" || this.register.sexo_id == null || this.register.sexo_id == 0) {
      this.alerts.notifyError({
        body: `Favor preencher o campo sexo`,
        title: 'Erro'
      });
      this.loading = false;
      return;
    }



    // if (this.register.rg == "" || this.register.rg == null) {
    //   this.alerts.notifyError({
    //     body: `Favor preencher o campo rg (Identidade)`,
    //     title: 'Erro'
    //   });
    //   this.loading = false;

    //   return;
    // }

    // if (this.register.orgao == "" || this.register.orgao == null) {
    //   this.alerts.notifyError({
    //     body: `Favor preencher o campo orgão emissor`,
    //     title: 'Erro'
    //   });
    //   this.loading = false;

    //   return;
    // }

    if (this.register.celular == "" || this.register.celular == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo telefone`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }



    if (!this.regexTelefone.test(this.register.celular)) {
      this.alerts.notifyError({
        body: `Campo telefone inválido`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }


    if (this.register.cpf == "" || this.register.cpf == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo cpf`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }


    if (this.register.cep == "" || this.register.cep == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo cep`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }


    if (this.register.estado_id == null || this.register.estado_id == 0) {
      this.alerts.notifyError({
        body: `Favor preencher o campo estado`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.register.cidade_id == null || this.register.cidade_id == 0) {
      this.alerts.notifyError({
        body: `Favor preencher o campo estado`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.register.logradouro == "" || this.register.logradouro == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo endereço`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.register.bairro == "" || this.register.bairro == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo bairro`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.register.numero == "" || this.register.numero == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo numero`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }


    if (this.register.email == "" || this.register.email == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo e-mail`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }


    if (this.register.email.trim() != this.confirmEmail.trim()) {
      this.alerts.notifyError({
        body: `Favor verificar campo e-mail`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }


    if (!this.lengthPass || !this.lettersPass || !this.numberPass) {
      this.alerts.notifyError({
        body: `Favor preencher o campo senha corretamente`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.register.senha == "" || this.register.senha == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo senha`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.confirmPassword == "" || this.confirmPassword == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo confirmar senha`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    if (this.confirmPassword != this.register.senha) {
      this.alerts.notifyError({
        body: `Senhas não condizem`,
        title: 'Erro'
      });
      this.loading = false;

      return;
    }

    var obj = Object.assign({}, this.register);

    obj.email = obj.email.trim();
    obj.telefone = {
      codigo_pais: "55",
      codigo_area: obj.celular.substring(0, 2),
      numero: obj.celular.substring(2)
    };



    obj.endereco = {
      cep: obj.cep,
      logradouro: obj.logradouro,
      numero: obj.numero,
      complemento: obj.complemento,
      bairro: obj.bairro,
      cidade_id: obj.cidade_id,
      estado_id: obj.estado_id
    };
    obj.senha = this.global.MD5(obj.senha);

    if (obj.data == "" || obj.data == null) {
      this.alerts.notifyError({
        body: `Favor preencher data de nascimento`,
        title: 'Erro'
      });
      return;
    } else {
      let nova_data = obj.data.split('/')[2] + "-" + obj.data.split('/')[1] + "-" + obj.data.split('/')[0];
      if (new Date(nova_data + "T00:00:00").toString() == "Invalid Date") {
        this.alerts.notifyError({
          body: `Favor preencher o campo data nascimento com uma data válida`,
          title: 'Erro'
        });
        return;
      }
      obj.data_nascimento = nova_data;
    }


    //PROVISÓRIO
    // obj.identidade = {
    //   orgao: obj.orgao,
    //   numero: obj.rg
    // };

    delete obj.data;
    delete obj.celular;
    delete obj.cep;
    delete obj.logradouro;
    delete obj.complemento;
    delete obj.bairro;
    delete obj.cidade_id;
    delete obj.estado_id;
    delete obj.orgao;
    delete obj.rg;


    this.sendUser(obj);

  }

  validarSenha(senha) {
    if (senha == null || senha == "" || senha.length <= 5) {
      this.lengthPass = false;
    } else {
      this.lengthPass = true;
    }
    if (!/[A-Za-z.\s_-]/.test(senha)) {
      this.lettersPass = false;
    } else {
      this.lettersPass = true;
    }
    if (!/[0-9]/.test(senha)) {
      this.numberPass = false;

    } else {
      this.numberPass = true;

    }
    // if (!/[*@._-]/.test(senha)) {
    //   this.caracterPass = false;

    // } else {
    //   this.caracterPass = true;

    // }
  }

  searchCep() {
    if (this.register.cep != null && this.register.cep != "" && this.register.cep.length == 8) {
      this.getCep(this.register.cep)
    }
  }

  selectState(estado_id) {
    if (estado_id != null && estado_id != 0) {
      this.getCidades(estado_id);
    } else {
      this.register.cidade_id = 0;
    }
  }

  getStates() {
    this.apiService.GetPublic('Estados').then((res) => {
      this.listsEstate = res;
      this.listsEstate.unshift({
        "nome": "Estado",
        id: 0
      });
      this.register.estado_id = 0;
      if (this.register.estado_id != null && this.register.estado_id != 0) {
        this.getCidades(this.register.estado_id);
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar obter lista de estados`,
        title: 'Erro'
      });
    });
  }

  getCidades(id_estado, localidade = null) {
    this.apiService.GetPublic(`Estados/${id_estado}/Cidades`).then((res) => {
      this.listsCity = res;
      this.listsCity.unshift({
        "nome": "Cidade",
        id: 0
      });
      this.register.cidade_id = 0;
      if (localidade != null) {
        let verfiCidade = this.listsCity.filter(x => x.nome == localidade.toUpperCase());
        if (verfiCidade.length > 0) {
          this.register.cidade_id = verfiCidade[0].id;
        }
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar obter lista de cidades`,
        title: 'Erro'
      });
    });
  }

  getCep(cep) {
    this.viacep
      .buscarPorCep(cep)
      .pipe(
        catchError((error: CEPError) => {
          return EMPTY;
        })
      )
      .subscribe((endereco: Endereco) => {
        this.register.logradouro = endereco.logradouro;
        this.register.bairro = endereco.bairro;
        if (endereco.uf) {
          let verfiEstado = this.listsEstate.filter(x => x.sigla == endereco.uf.toUpperCase());
          if (verfiEstado.length > 0) {
            this.register.estado_id = verfiEstado[0].id;
            this.getCidades(this.register.estado_id, endereco.localidade);
          }
        }
      });
  }

  getSexo() {
    this.apiService.GetPublic('Sexo').then((res) => {
      this.listSexo = res;
      this.listSexo.unshift({
        "nome": "Sexo",
        id: 0
      });
      this.register.sexo_id = 0;
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar obter lista de sexo`,
        title: 'Erro'
      });
    });
  }

  sendUser(obj) {
    this.loading = true;
    this.apiService.PostPublic('Clientes', obj).then((res) => {
      let obj_ = {
        login: obj.email,
        senha: obj.senha
      }
      // obj.senha = this.global.MD5(obj.senha);
      this.authUser(obj_);
      // this.router.navigate(['login']);
    }).catch((err) => {
      this.loading = false;
      if (err.status == 524) {
        this.alerts.notifyError({
          body: `Erro: Favor tentar novamente`,
          title: 'Erro'
        });
      } else {
        this.alerts.notifyError({
          body: `Erro: ${JSON.stringify(err.error)}`,
          title: 'Erro'
        });
      }

    });
  }

  authUser(user) {
    this.loading = true;
    this.apiService.PostPublic('Auths', user).then((res) => {
      setTimeout(() => {
        this.alerts.notifySuccess({
          body: `Bem-vindo ao digital ingressos`,
          title: 'Sucesso'
        });
      }, 1000);
      if (this.router['rawUrlTree'].queryParams.returnUrl != null) {
        this.authService.loginUser(res, null, this.router['rawUrlTree'].queryParams.returnUrl);
      } else {
        this.authService.loginUser(res, null);
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }

  validarCPF(inputCPF) {
    if (!inputCPF) {
      this.cpfInvalid = false;
      return false;
    }
    var soma = 0;
    var resto;
    if (inputCPF == '00000000000') return false;
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) {
      this.cpfInvalid = false;
      return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) {
      this.cpfInvalid = false;
      return false;
    }

    this.cpfInvalid = true;
    return false;
  }

  validarEmail(str = "") {
    if (str) {
      return str.trim();
    } else {
      "";
    }
  }

}
