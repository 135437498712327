import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-modal-card-address',
  templateUrl: './modal-card-address.component.html',
  styleUrls: ['./modal-card-address.component.css']
})
export class ModalCardAddressComponent implements OnInit {
  global_: any;
  loading: any = false;
  ticket: any = {};
  seeEdit: any;
  listsEstate: any = [];
  listsCity: any = [];
  enderecoCartao: any = {};
  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    private viacep: NgxViacepService,
    public global: Global,
    public dialogRef: MatDialogRef<ModalCardAddressComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data) {
      this.enderecoCartao = data;
    }
  }

  ngOnInit() {
    this.getStates();
  }

  searchCep() {
    if (this.enderecoCartao.cep != null && this.enderecoCartao.cep != "" && this.enderecoCartao.cep.length == 8) {
      this.getCep(this.enderecoCartao.cep)
    }
  }

  selectState(estado_id) {
    if (estado_id != null && estado_id != 0) {
      this.getCidades(estado_id);
    } else {
      this.enderecoCartao.cidade_id = 0;
    }
  }

  getStates() {
    this.apiService.GetPublic('Estados').then((res) => {
      this.listsEstate = res;
      this.listsEstate.unshift({
        "nome": "Estado",
        id: 0
      });
      if (this.enderecoCartao.estado_id != null && this.enderecoCartao.estado_id != 0) {
        this.getCidades(this.enderecoCartao.estado_id);
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }

  getCidades(id_estado, localidade = null) {
    this.apiService.GetPublic(`Estados/${id_estado}/Cidades`).then((res) => {
      this.listsCity = res;
      this.listsCity.unshift({
        "nome": "Cidade",
        id: 0
      });
      // this.enderecoCartao.cidade_id = 0;
      if (localidade != null) {
        let verfiCidade = this.listsCity.filter(x => x.nome == localidade.toUpperCase());
        if (verfiCidade.length > 0) {
          this.enderecoCartao.cidade_id = verfiCidade[0].id;
        }
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }

  getCep(cep) {
    this.viacep
      .buscarPorCep(cep)
      .pipe(
        catchError((error: CEPError) => {

          return EMPTY;
        })
      )
      .subscribe((endereco: Endereco) => {
        this.enderecoCartao.logradouro = endereco.logradouro;
        this.enderecoCartao.bairro = endereco.bairro;
        if (endereco.uf) {
          let verfiEstado = this.listsEstate.filter(x => x.sigla == endereco.uf.toUpperCase());
          if (verfiEstado.length > 0) {
            this.enderecoCartao.estado_id = verfiEstado[0].id;
            this.getCidades(this.enderecoCartao.estado_id, endereco.localidade);
          }
        }
      });
  }

  submitAddress() {
    var obj = { ...this.enderecoCartao };

    if (obj.cep == null || obj.cep == "") {
      this.alerts.notifyError({
        body: `Favor preencher o cep`,
        title: 'Erro'
      });
      return;
    }

    if (obj.estado_id == null || obj.estado_id == 0) {
      this.alerts.notifyError({
        body: `Favor preencher o estado`,
        title: 'Erro'
      });
      return;
    }

    if (obj.cidade_id == null || obj.cidade_id == 0) {
      this.alerts.notifyError({
        body: `Favor preencher a cidade`,
        title: 'Erro'
      });
      return;
    }

    if (obj.logradouro == null || obj.logradouro == "") {
      this.alerts.notifyError({
        body: `Favor preencher o logradouro`,
        title: 'Erro'
      });
      return;
    }

    if (obj.bairro == null || obj.bairro == "") {
      this.alerts.notifyError({
        body: `Favor preencher o bairro`,
        title: 'Erro'
      });
      return;
    }

    if (obj.numero == null || obj.numero == "") {
      this.alerts.notifyError({
        body: `Favor preencher o numero`,
        title: 'Erro'
      });
      return;
    }

    this.dialogRef.close(obj);
  }

  showAlert(msg) {
    this.alerts.notifyError({
      body: `Erro ${msg}`,
      title: 'Erro'
    });
  }

}
