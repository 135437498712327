import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmEmailComponent implements OnInit {
  loading: any = false;
  register: any = {};

  listsEstate: any = [];
  listsCity: any = [];
  listSexo: any = [];

  cpfInvalid: any = false;
  rgInvalid: any = false;
  confirmEmail: any = null;
  confirmPassword: any = null;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  lettersPass: any = false;
  numberPass: any = false;
  caracterPass: any = false;
  lengthPass: any = false;
  token: any = "";
  valido: any = "Validando";

  constructor(public apiService: ApiService,
    private alerts: NgxAlertsService,
    public global: Global,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap['params'];
    if (Object.keys(this.token)[0] != null && Object.keys(this.token)[0] != "") {
      this.send();
    } else {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Link inválido`,
      });
      this.valido = "Link não é valido";
    }
  }


  send() {
    this.loading = true;
    this.apiService.PatchAlterPassword('Clientes/Email/Validar', {}, Object.keys(this.token)[0]).then((res) => {
      setTimeout(() => {
        this.alerts.alertSuccess({
          text: `Sucesso ao validar email`,
          title: 'Sucesso'
        });
      });
      this.valido = "Email confirmado";
      this.router.navigate(['login']);
    }).catch((err) => {
      this.loading = false;

      if (err.status == 401) {
        this.alerts.alertError({
          title: 'Atenção',
          text: `Link inválido`,
        });
        this.valido = "Link não é valido";
      } else {
        this.alerts.alertError({
          text: `${err.error}`,
          title: 'Alerta'
        });
        this.valido = "Email não validado";

      }
    });
  }

}
