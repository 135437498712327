import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var Swiper: any;
declare var $: any;
import { Title } from "@angular/platform-browser";
import { MatDialog } from '@angular/material';
import { ModalPhotoUserComponent } from 'src/app/components/modal-photo-user/modal-photo-user.component';
@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class Ticketsomponent implements OnInit {
  user: any = {};
  tickets: any = [];
  payments: any = [];

  seeEdit: any;
  global_: any;
  codigo_pdv: any = "";
  imgExiste: any = false;
  constructor(
    private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    public global: Global,
    private route: ActivatedRoute,
    private titleService: Title,
    public dialog: MatDialog,
  ) {
    this.titleService.setTitle('Digital Ingressos');
    var currentUser = localStorage.getItem('@DIngress:currentUser');
    localStorage.setItem('@DIngress:checkOutData', "");
    localStorage.setItem('@DIngress:checkOutDataCode', "");
    localStorage.setItem('@DIngress:checkOutDataArea', "");
    this.user = JSON.parse(currentUser);
    this.global_ = this.global;

  }


  setEditUser(value) {
    this.seeEdit = value;
  }

  removeAbas() {
    var elements = $(".btNavUser");
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].classList.contains('btActive')) {
        elements[i].classList.remove('btActive')
      }
    }
  }

  ngOnInit() {
    const codigo_pdv = this.route.snapshot.paramMap.get('codigo_pdv');
    if (codigo_pdv) {
      this.codigo_pdv = codigo_pdv;
    }
    this.authService.setPdvCodigo(this.codigo_pdv)
    this.getTickets();
    this.getUserCliente();
  }

  getTickets() {
    this.apiService.Get('MeuIngresso/Ingressos/Proximo').then((res) => {
      this.tickets = res;
    }).catch((err) => {
      this.alerts.notifyError({
        body: `Erro ao tentar realizar buscar dados`,
        title: 'Erro'
      });
    });
  }

  getPayments() {
    this.apiService.Get('Pagamentos').then((res) => {
      this.payments = res;
    }).catch((err) => {
      this.alerts.notifyError({
        body: `Erro ao tentar realizar  buscar dados`,
        title: 'Erro'
      });
    });
  }


  getUserCliente() {
    this.apiService.Get(`Clientes`).then((res) => {
      this.user = res[0];
      const regex = /foto\/(.+)/;

      // Testar a URL com a expressão regular
      const match = this.user.imagem_link.match(regex);

      if (match && match[1]) {
        console.log("Algo encontrado após 'foto/':", match[1]);
        this.imgExiste = true;
      } else {
        this.imgExiste = false;
        console.log("Nada encontrado após 'foto/'.");
      }
    }).catch((err) => {
      if (err.status == 401) {
        this.authService.logoutUser();
      } else {

        this.alerts.notifyError({
          body: `Erro ${err.error}`,
          title: 'Erro'
        });
      }
    });
  }

  getStatus(value) {
    if (value) {
      switch (value) {
        case "PAGO":
          return "APROVADO";
        case "REJEITADO":
          return "REJEITADO";
        case "CRIADO":
          return "CRIADO";
        case "CANCELADO":
          return "CANCELADO";
        case "DEVOLVIDO":
          return "DEVOLVIDO";
        default:
          return value
      }
    }
  }


  openModalPhoto() {
    const dialogRef = this.dialog.open(ModalPhotoUserComponent, {
      width: 'auto',
      height: 'auto',
      maxWidth: '500px',
      data: this.user,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUserCliente();
    });
  }


}
