import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var $: any;
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading: any = false;
  checkout: any = "";
  user: any = {};
  socialLogin: any = {
    palmspay: 1,
    google: 2,
    facebook: 3,
  }
  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    public global: Global,
    private titleService: Title
  ) {
    this.titleService.setTitle('Digital Ingressos');
    $("body").attr('style', 'background-color: #ecebee');
    this.authService.currentCheckout.subscribe(res => {
      if (res) {
        this.checkout = res;
      }
    })
  }

  ngOnInit() {

  }

  sendValidation() {
    if (this.loading) {
      return;
    }
    if (this.user.login == "" || this.user.login == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo email`,
        title: 'Erro'
      });
      return;
    }

    if (this.user.senha == "" || this.user.senha == null) {
      this.alerts.notifyError({
        body: `Favor preencher o campo senha`,
        title: 'Erro'
      });
      return;
    }

    let obj = Object.assign({}, this.user);
    obj.senha = this.global.MD5(obj.senha);

    this.authUser(obj);
  }

  authUser(user) {
    this.loading = true;
    this.apiService.PostPublic('Auths', user).then((res) => {
      if (this.router['rawUrlTree'].queryParams.returnUrl != null) {
        this.authService.loginUser(res, this.checkout, this.router['rawUrlTree'].queryParams.returnUrl);
      } else {
        this.authService.loginUser(res, this.checkout);
      }
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }


  loginSocial(type) {
    this.router.navigate(['home']);
  }

  registerUser() {
    if (this.router['rawUrlTree'].queryParams.returnUrl != null) {
      this.router.navigate(['register'], { queryParams: { returnUrl: this.router['rawUrlTree'].queryParams.returnUrl } });
    } else {
      this.router.navigate(['register']);
    }
  }


  redefinirSenha() {
    this.router.navigate(['reset-password']);
  }
}
