import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TagManagerService {
    private tagManagerId: string;
    private gtmScript: HTMLScriptElement | null = null; // Armazena a referência do script

    constructor() {
        this.tagManagerId = ''; // Defina o valor dinâmico aqui
    }

    setTagManagerId(id: string) {
        this.tagManagerId = id;
    }

    getTagManagerId(): string {
        return this.tagManagerId;
    }

    addGtmScript() {
        if (!this.tagManagerId) {
            console.error('Tag Manager ID não foi definido.');
            return;
        }

        // Verifica se o script já foi adicionado para evitar múltiplas inserções
        // if (this.gtmScript) {
        //     console.warn('O script do Google Tag Manager já foi adicionado.');
        //     return;
        // }

        this.gtmScript = document.createElement('script');
        this.gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.tagManagerId}`;
        this.gtmScript.async = true;

        this.gtmScript.onload = () => {
            // Quando o script é carregado, inicialize o gtag
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).gtag = function () {
                (window as any).dataLayer.push(arguments);
            };
            (window as any).gtag('js', new Date());
            (window as any).gtag('config', this.tagManagerId);
        };

        document.head.appendChild(this.gtmScript);
    }

    removeGtmScript() {
        // Remove todos os scripts do GTM que não sejam o fixo G-MXXPTVD9HV
        const scripts = document.querySelectorAll<HTMLScriptElement>('script[src*="googletagmanager.com/gtag/js?id="]');
        scripts.forEach(script => {
            if (!script.src.includes('G-MXXPTVD9HV')) {
                document.head.removeChild(script);
                // console.log(`Script removido: ${script.src}`);
            }
        });

        // Limpa o dataLayer (opcional)
        if ((window as any).dataLayer) {
            (window as any).dataLayer = [];
        }

        // Remove a função gtag (somente para os scripts dinâmicos)
        // if ((window as any).gtag && !this.tagManagerId.includes('G-MXXPTVD9HV')) {
        //     delete (window as any).gtag;
        // }

        // console.log('Todos os scripts de GTM dinâmicos foram removidos, exceto G-MXXPTVD9HV.');
    }

    gtag(pagePath: string) {
        if (!(window as any).gtag) {
            console.error('gtag não foi carregado.');
            return;
        }

        // Chama a função gtag após o script ter sido carregado
        (window as any).gtag('config', this.tagManagerId, {
            page_path: pagePath,
        });
    }
}
