import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-item-buy',
  templateUrl: './item-buy.component.html',
  styleUrls: ['./item-buy.component.css']
})
export class ItemBuyComponent implements OnChanges {

  @Input() arrayList: any = [];
  @Input() codigo_pdv: any = "";

  constructor(private router: Router) { }

  ngOnChanges() {   
  }

  goToEvent(d) {
    if (this.codigo_pdv) {
      this.router.navigate([`/event/${d.codigo}/${this.codigo_pdv}`]);
    } else {
      this.router.navigate(['/event/' + d.codigo]);
    }
  }

  formaterCurrency(value) {
    if (value) {
      if (value.includes("R$")) {
        return value.replace("R$", "").trim();
      }
      return value;
    }
    return value

  }

  formatDateDayMonth(value) {
    if (value) {
      return moment(value).format("DD/MM")
    }

    return "--/--"
  }

}
