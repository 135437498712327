import { Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { MapsAPILoader } from '@agm/core';
import { MatDialog } from '@angular/material/dialog';
declare var Swiper: any;
declare var $: any;
import { Title } from "@angular/platform-browser";
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {
  loading: any = false;
  register: any = {};

  listsEstate: any = [];
  listsCity: any = [];
  listSexo: any = [];

  cpfInvalid: any = false;
  rgInvalid: any = false;
  confirmEmail: any = null;
  confirmPassword: any = null;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  lettersPass: any = false;
  numberPass: any = false;
  caracterPass: any = false;
  lengthPass: any = false;

  constructor(public apiService: ApiService,
    private alerts: NgxAlertsService,
    public global: Global,
    private authService: AuthService,
    private router: Router,
    private titleService: Title
    ) {
      this.titleService.setTitle('Digital Ingressos');
  }

  ngOnInit() {

  }

  sendValidation() {

    if (this.loading) {
      return;
    }

    if (this.register.email == "" || this.register.email == null) {
      this.alerts.alertError({
        text: `Favor preencher o campo e-mail`,
        title: 'Alerta'
      });
      this.loading = false;

      return;
    }


    if (this.register.email != this.confirmEmail) {
      this.alerts.alertError({
        text: `Favor verificar campo e-mail`,
        title: 'Alerta'
      });
      this.loading = false;

      return;
    }


    var obj = Object.assign({}, this.register);
    this.send(obj.email);
  }

  send(email) {
    this.loading = true;
    this.apiService.PostPublic('Auths/Reset', email).then((res) => { 
      setTimeout(() => {
        this.alerts.alertSuccess({
          text: `Verificar o seu WhatsApp ou email`,
          title: 'Sucesso'
        });
      });
      this.router.navigate(['login']);
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.alerts.alertError({
        text: `${err.error}`,
        title: 'Alerta'
      });
    });
  }



}
