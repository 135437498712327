import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-separador',
  templateUrl: './banner-separador.component.html',
  styleUrls: ['./banner-separador.component.css']
})
export class BannerSeparador implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
