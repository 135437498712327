import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { ModalSelectLocationComponent } from '../modal-select-location/modal-select-location.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  codigo_pdv: any = "";
  pdv: any = null;
  listaEstados: any = [];
  stateSelected: any = 1;
  stateSelectedString: any = "Brasil";
  lat: number = 0;
  lng: number = 0;
  zoom: number = 15;
  private geoCoder;
  constructor(private router: Router, private route: ActivatedRoute, private global: Global,
    public apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {

    this.authService.estados.subscribe(async (res) => {
      if (res) {
        this.listaEstados = res;
        var location_ = await localStorage.getItem('@DIngress:location');
        if (location_) {
          let selected: any = JSON.parse(location_)
          let filter_ = this.listaEstados.filter(x => x.nome == selected[0].nome);

          if (filter_.length) {
            this.stateSelected = selected[0].id;
            this.stateSelectedString = selected[0].nome;
            this.selectState(selected[0].id, res)
          } else {
            selected = [];
            selected.push(this.listaEstados[0]);
            this.stateSelected = selected[0].id;
            this.stateSelectedString = selected[0].nome;
            this.authService.setLocation(JSON.stringify(selected));
            this.selectState(selected[0].id, this.listaEstados)
          }
        } 
        localStorage.setItem('@DIngress:locations', JSON.stringify(res));

        this.authService.setLocations(JSON.stringify(res));
      }
    });

    this.authService.pdv_codigo.subscribe(res => {
      if (res) {
        this.codigo_pdv = res;
        this.getPdv();
      }
    });

  }

  ngOnInit() {
    this.init();
  }

  async init() {
    //this.setCurrentLocation();
    // this.getEstados();
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 8;
      });
    }
  }

  selectState(state: any, states: any) {
    var selected = this.listaEstados.filter(x => x.id == state);
    this.stateSelectedString = selected[0].nome;
    localStorage.setItem('@DIngress:location', JSON.stringify(selected));
    localStorage.setItem('@DIngress:locations', JSON.stringify(states));
    this.authService.setLocation(JSON.stringify(selected));
    this.authService.setLocations(JSON.stringify(states));

  }

  getEstados() {
    let cod_ = "";
    if (this.codigo_pdv) {
      cod_ = `Eventos/Estados?codigo_pdv=${this.codigo_pdv}`;
    } else {
      cod_ = `Eventos/Estados`;
    }

    this.apiService.GetPublic(cod_).then(async (res) => {
      this.listaEstados = res;
      var location_ = await localStorage.getItem('@DIngress:location');
      if (location_) {

        var selected = JSON.parse(location_)
        let filter_ = this.listaEstados.filter(x => x.nome == selected[0].nome)
        if (filter_.length) {
          this.stateSelected = selected[0].id;
          this.stateSelectedString = selected[0].nome;
        } else {
          selected = this.listaEstados;
          this.stateSelected = selected[0].id;
          this.stateSelectedString = selected[0].nome;
        }
      }
      localStorage.setItem('@DIngress:locations', JSON.stringify(res));
      this.authService.setLocations(JSON.stringify(res));

    }).catch((err) => {
    });
  }
  getPdv() {
    this.apiService.GetPublic(`PdvEmpresa/${this.codigo_pdv}`).then((res) => {
      this.pdv = res;
    }).catch((err) => {
    });
  }
  goToRoute(r = "") {
    if (this.codigo_pdv) {
      this.router.navigate([`/${r}/${this.codigo_pdv}`]);
    } else {
      this.router.navigate([`/${r}`]);
    }
  }


  openModalLocation() {
    const dialogRef = this.dialog.open(ModalSelectLocationComponent, {
      width: '300px',
      height: 'auto',
      maxWidth: '100%',
      data: this.listaEstados,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stateSelectedString = result[0].nome;
        this.stateSelected = result[0].id;
      }
    });
  }
}
