import { Provider, APP_BOOTSTRAP_LISTENER, ComponentRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ApiService } from './apiServices';
import { FacebookPixelService } from './facebook-pixel.service'

export const FACEBOOK_PIXEL_PROVIDER: Provider = {
    provide: APP_BOOTSTRAP_LISTENER,
    multi: true,
    useFactory: FacebookPixelRouterInitializer,
    deps: [
        FacebookPixelService,
        Router,
        ApiService
    ]
};

export function FacebookPixelRouterInitializer(
    $fpService: FacebookPixelService,
    $router: Router,
    $apiService: ApiService

) {
    return async (c: ComponentRef<any>) => {
        $router
            .events
            .subscribe(event => {
                if (event instanceof NavigationEnd) {
                    // console.log(`Navigated to URL: ${event.url}`);
                    const codigo_pdv = event.url.split("/")[event.url.split("/").length - 1];
                    if (codigo_pdv.includes("PDV")) {
                        $apiService.GetPublic(`PdvEmpresa/${codigo_pdv}`).then((res) => {
                            if (res['tag_pixel']) {
                                $fpService.load(res['tag_pixel']);
                            }
                        }).catch((err) => {
                        });
                    } else if (event.url.includes("event")) {
                        $apiService.GetPublic(`Eventos/${codigo_pdv}`).then((res) => {
                            if (res['tag_pixel_meta']) {
                                $fpService.load(res['tag_pixel_meta']);
                            }
                        }).catch((err) => {
                        });
                    } else if (event.url.includes("payment")) {
                        var checkOut = JSON.parse(localStorage.getItem('@DIngress:checkOut'));
                        if (checkOut) {
                            $apiService.GetPublic(`Eventos/${checkOut.evento.codigo}`).then((res) => {
                                if (res['tag_pixel_meta']) {
                                    $fpService.load(res['tag_pixel_meta']);
                                }
                            }).catch((err) => {
                            });
                        }
                    }
                }
            });
    };
}
