import { Component, Input, OnChanges } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
import { ModalTransferComponent } from '../../modal-transfer/modal-transfer.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-transfer-ticket',
  templateUrl: './transfer-ticket.component.html',
  styleUrls: ['./transfer-ticket.component.css']
})
export class TransferTicketComponent implements OnChanges {
  @Input() tickets = [];
  ticketsObj: any = [];
  seeTicket: any = false;
  global_: any;
  bilhetesSelecionados: any = [];
  constructor(private global: Global,
    public apiService: ApiService,
    private authService: AuthService,
    private alerts: NgxAlertsService,
    public dialog: MatDialog
    // public modalController: ModalController,
  ) {
    this.global_ = this.global;
  }

  ngOnChanges() {
    this.ticketsObj = this.tickets;
  }

  transferTicket(ticket) {
    const dialogRef = this.dialog.open(ModalTransferComponent, {
      width: 'auto',
      data: ticket
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTickets();
    });
  }

  getTickets() {
    this.apiService.Get('MeuIngresso/Transferir').then((res) => {
      this.ticketsObj = res;
    }).catch((err) => {
      this.alerts.notifyError({
        body: `Erro ao tentar obter ingressos`,
        title: 'Erro'
      });
    });
  }

  async showModalTransfer(img = "") {

  }
}
